





















































import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
@Component
export default class LockScreenView extends Vue {
	@Prop() private size!: string;
	@Prop() private data!: object;
}
