function hasClass(elements: HTMLElement, cName: string) {
	return !!elements.className.match(
		new RegExp('(\\s|^)' + cName + '(\\s|$)')
	);
}
function addClass(elements: HTMLElement, cName: string) {
	if (!hasClass(elements, cName)) {
		elements.className += ' ' + cName;
	}
}
function removeClass(elements: HTMLElement, cName: string) {
	if (hasClass(elements, cName)) {
		elements.className = elements.className.replace(
			new RegExp('(\\s|^)' + cName + '(\\s|$)'),
			' '
		);
	}
}
export default {
	bind: function(el: HTMLElement) {
		const parent = el;
        // @ts-ignore: 不需要与这代码打交道
        const childrens = [...parent.children] as NodeListOf<HTMLElement>
		const length: number = childrens.length;
		parent.style.position = 'relative';
		for (let i = 0; i <= length - 1; i++) {
			childrens[i].style.zIndex = '1';
			childrens[i].style.position = 'absolute';
			childrens[i].style.opacity = '0';
			childrens[i].style.transition = 'all 400ms';
			childrens[i].style.webkitTransition = 'all 400ms';
		}
		const run = function(index: number) {
			index = index > length - 1 ? 0 : index;
			const preIndex = index == 0 ? length - 1 : index - 1;
			const currentDom = childrens[index];
			const prevDom = childrens[preIndex];
			currentDom.style.zIndex = '2';
			currentDom.style.opacity = '1';
			addClass(currentDom, 'swiper-slide-active');
			prevDom.style.zIndex = '1';
			prevDom.style.opacity = '0';
			removeClass(prevDom, 'swiper-slide-active');
			setTimeout(function() {
				run(++index);
			}, 3000);
		};
		run(0);
	}
};
