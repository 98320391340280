export function easeout(A, B, rate, crisis, callback) {
    if (!window.requestAnimationFrame) {
      window.requestAnimationFrame = function(fn) {
        setTimeout(fn, 17);
      };
    }
    if (A === B || typeof A !== "number") {
      return;
    }
    B = B || 0;
    rate = rate || 2;
    crisis = crisis || 1;
    const step = function() {
      A = A + (B - A) / rate;
      // console.log(A, B)
      if (Math.abs(A - B) < crisis) {
        callback(B, true);
        return;
      }
      callback(A, false);
      window.requestAnimationFrame(step);
    };
    step();
  }
  // h5 获取到顶部高度
  export function offset(target) {
    const offset = { top: 0, left: 0 };
    do {
      offset["left"] += target.offsetLeft;
      offset["top"] += target.offsetTop;
  
      /* eslint-disable */
    } while ((target = target.offsetParent));
    return offset;
  }
  
// h5获取滚动高度
export function getScrollTop() {
  return (
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop
  );
}
// 滚动条函数封装
export function scrollTo(className, justify = 0, cb) {
  let number = 0;
  if (typeof className === "number") {
    number = className + justify;
  } else if (className instanceof window.Node) {
    number = offset(className).top + justify;
  } else if (typeof className === "string") {
    number = offset(document.querySelector(className)).top + justify;
  }
  // calcScroll(number, time)
  // test(number)

  easeout(getScrollTop(), number, 4, 2, function(value, isEnding) {
    document.body.scrollTop = document.documentElement.scrollTop = value;
    if (isEnding) {
      cb && cb();
    }
  });
}

