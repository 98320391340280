












































































































































































































































































































import LockScreenView from '@/components/LockScreenView.vue';
import { Component, Vue } from 'vue-property-decorator';
import swiper from '@/directives/swiper'
// @ts-ignore: 不需要与这代码打交道
import { scrollTo } from '@/utils/index.js';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init();

interface LocalizedObj {
	imgUrl: string;
	paperId: number;
	title: string;
	desc: string;
	countryIcon: string;
	countryId: number;
}
@Component({
	data: function() {
		return {
			paperId: 0, // 图片id
			countryId: 0, // 国家id
			phoneModalFlag: false,
			swiperOption: {
				effect: 'fade',
				loop: true,
				autoplay: true
			},
			productFeaturesData: [
				{
					imgUrl: require('../assets/img/productFeatures/401305.jpg'),
					title: '粉红色的玫瑰',
					desc: '玫瑰的花蕾即将绽放，可以看到它一层一层的花瓣。'
				},
				{
					imgUrl: require('../assets/img/productFeatures/492339.jpg'),
					title: '湖边的夜景',
					desc: '古老的欧式建筑的夜景，是旅途中最美的记忆之一。'
				},
				{
					imgUrl: require('../assets/img/productFeatures/571813.jpg'),
					title: '沙滩上空无一人',
					desc: '春天到佛罗里达海滩度假村游玩时拍摄的照片。'
				},
				{
					imgUrl: require('../assets/img/productFeatures/301433.jpg'),
					title: '水中的褐鸭',
					desc:
						'褐鸭主要以淡水和咸水中的水生无脊椎动物和植物碎片为食。'
				}
			],
			localizedData: [
				[
					{
						imgUrl: require('../assets/img/localized/233637.jpg'),
						title: '自由女神像',
						desc: '自由女神像是一个巨大的新古典主义雕塑，现矗立在美国纽约自由岛。',
						countryIcon: require('../assets/img/america.png'),
						countryId: 2,
						paperId: 508137,
						btnName: 'more'
					}
				],
				[
					{
						imgUrl: require('../assets/img/localized/14129.jpg'),
						title: '雄伟壮观的北京天坛',
						desc:
							'天坛是明清两代皇帝祭祀天地之神，和祈祷五谷丰收的地方。',
						countryIcon: require('../assets/img/china.png'),
						countryId: 2,
						paperId: 508137,
						btnName: '查看'
					},
					{
						imgUrl: require('../assets/img/localized/262327.jpg'),
						title: '中国北京颐和园',
						desc:
							'中国清朝时期皇家园林，前身为清漪园，坐落在北京西郊。',
						countryId: 2,
						paperId: 508137,
						btnName: '查看'
					}
				],
				[
					{
						imgUrl: require('../assets/img/localized/495282.jpg'),
						title: '法国巴黎埃菲尔铁塔',
						desc: '世界著名建筑、法国文化象征之一，被法国人爱称为“铁娘子” 。',
						countryIcon: require('../assets/img/france.png'),
						countryId: 2,
						paperId: 508137,
						btnName: 'Voir'
					},
					{
						imgUrl: require('../assets/img/localized/161879.jpg'),
						title: '法国巴黎卢浮宫博物馆',
						desc:
							'卢浮宫位于法国巴黎市中心的塞纳河北岸，位居世界四大博物馆之首。',
						countryId: 2,
						paperId: 508137,
						btnName: 'Voir'
					}
				],
				[
					{
						imgUrl: require('../assets/img/localized/144858.jpg'),
						title: '阳光明媚的塞维利亚',
						desc:
							'塞维利亚是西班牙的第四大城市，这里也曾是一个重要港口。',
						countryIcon: require('../assets/img/spain.png'),
						countryId: 2,
						paperId: 508137,
						btnName: 'Más'
					},
					{
						imgUrl: require('../assets/img/localized/7750.jpg'),
						title: '奇特的现代建筑风格',
						desc:
							'现代建筑不是随着20世纪的来临而突然出现的。',
						countryId: 2,
						paperId: 508137,
						btnName: 'Más'
					}
				],
				[
					{
						imgUrl: require('../assets/img/localized/8132.jpg'),
						title: '莫斯科城的螺旋建筑',
						desc:
							'"莫斯科" - 俄罗斯的政治、经济、文化、金融、交通中心以及最大的综合性城市，是一座国际化大都市。',
						countryIcon: require('../assets/img/russia.png'),
						countryId: 2,
						paperId: 508137,
						btnName: 'Подробно'
					}
				],
				[
					{
						imgUrl: require('../assets/img/localized/2727.jpg'),
						title: '印度泰姬陵',
						desc:
							'泰姬陵是印度艺术最完美的瑰宝，是世界遗产中的经典杰作之一。',
						countryIcon: require('../assets/img/india.png'),
						countryId: 2,
						paperId: 2727,
						btnName: 'विस्तार से'
					}
				]
			],
			selectiveData: [
				[
					{
						imgUrl: require('../assets/img/selective/558391.jpg'),
						className: 'wrong'
					}
				],
				[
					{
						imgUrl: require('../assets/img/selective/299747.jpg'),
						className: 'right'
					},
					{
						imgUrl: require('../assets/img/selective/589001.jpg'),
						className: 'wrong'
					}
				],
				[
					{
						imgUrl: require('../assets/img/selective/589009.jpg'),
						className: 'wrong'
					}
				],
				[
					{
						imgUrl: require('../assets/img/selective/10316.jpg'),
						className: 'right'
					}
				]
			],
			richContentData: [
				//分类
				{
					imgUrl: require('../assets/img/richContent/457089.jpg'),
					title: '绿色的摩托车',
					desc:
						'这是一款经典的摩托车，外观是墨绿色的，十分引人注意。',
					paperId: 508137
				},
				{
					imgUrl: require('../assets/img/richContent/429803.jpg'),
					title: '瑞士的少女峰',
					desc: '少女峰拥有欧洲最高的巧克力工厂，海拔2061米的艾格峰就坐落于此。',
					paperId: 508137
				},
				{
					imgUrl: require('../assets/img/richContent/546615.jpg'),
					title: '广州塔里的风景',
					desc: '塔内的广州四季酒店，带给你独特的视角和体验。',
					paperId: 508137
				},
				{
					imgUrl: require('../assets/img/richContent/254352.jpg'),
					title: '藏红花的习性',
					desc:
						'藏红花喜冷阴环境，较耐寒，宜排水良好、腐殖质丰富的沙壤土。',
					paperId: 508137
				},
				{
					imgUrl: require('../assets/img/richContent/300860.jpg'),
					title: '立体抽象艺术作品',
					desc:
						'抽象艺术是通过色彩、线条、色块、构成来表达和叙述人性的艺术方式。',
					paperId: 508137
				},
				{
					imgUrl: require('../assets/img/richContent/9306.jpg'),
					title: '好看又好吃的减肥餐',
					desc:
						'这是一道适合健身者的晚餐，餐品里有生菜、鸡胸肉和鸡蛋。',
					paperId: 508137
				},
				{
					imgUrl: require('../assets/img/richContent/315638.jpg'),
					title: '好奇的猫鼬',
					desc: '没事就爱站起来卖萌，猫鼬已经成为了宠物界冉冉升起的新明星。',
					paperId: 508137
				},
				{
					imgUrl: require('../assets/img/richContent/442360.jpg'),
					title: '棕色的梗类小型犬',
					desc: '我的小狗，名字叫米歇尔，是一条梗类小型犬。',
					paperId: 508137
				}
			]
		};
	},
	directives: {
        swiper: swiper
	},
	components: {
		LockScreenView
	}
})
export default class Index extends Vue {
	goBottom() {
		scrollTo('.about-us');
	}
	togglePhoneModal(flag: boolean) {
		this.$data.phoneModalFlag = flag;
	}
	showLocalized(item: LocalizedObj) {
		if (item.countryId) {
			this.$data.paperId = item.paperId;
			this.$data.countryId = item.countryId;
			this.$data.phoneModalFlag = true;
		}
	}
	richContentSelect(id: number) {
		if (id) {
			this.$data.paperId = id;
			this.$data.countryId = 2;
			this.$data.phoneModalFlag = true;
		}
	}
}
